import React from 'react'
import { graphql } from 'gatsby'
import MembersPage from 'components/MembersPage'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { ContentfulMembersListData, Location } from 'shared/types'
import { genericTitle, genericdescription, baseUrl, homeImage } from 'shared/constants'
import { MEMBERS_PAGE } from 'shared/routes'
import { mapToMembersList, mapToGeneralPageFields } from 'services'

interface Members {
  location: Location
  data: ContentfulMembersListData
}

const Members = ({ location, data }: Members): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const membersLists = mapToMembersList(data)
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${MEMBERS_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <MembersPage membersLists={membersLists} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query Members($locale: String) {
    allContentfulMembersList(filter: { node_locale: { eq: $locale } }) {
      nodes {
        category
        members {
          contentful_id
          title
          name
          genie
          urlLinkedIn
          image {
            fixed(height: 300, width: 300) {
              src
            }
          }
        }
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Members
