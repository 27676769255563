import React from 'react'
import { Container, Row } from 'reactstrap'
import { MembersList } from 'shared/types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import MemberCard from './MemberCard'

interface MembersPage {
  membersLists: MembersList[]
}

const MembersPage = ({ membersLists }: MembersPage): JSX.Element => {
  const executives = membersLists.find((membersList) => membersList.category === 'exécutifs')
  const usefulMembers = membersLists.find((membersList) => membersList.category === 'indispensables')
  const members = membersLists.find((membersList) => membersList.category === 'indissociables')

  return (
    <div className="main">
      <div className="section text-center top-of-page">
        <Container>
          <h2 className="title">
            <FormattedMessage id="member_title_exec" />
          </h2>
          <Row>
            {executives?.members.map((m) => (
              <MemberCard
                key={m.id}
                image={m.image}
                name={m.name}
                title={m.title}
                genie={m.genie}
                urlLinkedIn={m.urlLinkedIn}
              />
            ))}
          </Row>
          <h2 className="title">
            <FormattedMessage id="member_title_indispensable" />
          </h2>
          <Row>
            {usefulMembers?.members.map((m) => (
              <MemberCard
                key={m.id}
                image={m.image}
                name={m.name}
                title={m.title}
                genie={m.genie}
                urlLinkedIn={m.urlLinkedIn}
              />
            ))}
          </Row>
          <h2 className="title">
            <FormattedMessage id="member_title_inseparable" />
          </h2>
          <Row>
            {members?.members.map((m) => (
              <MemberCard key={m.id} name={m.name} noSpace />
            ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MembersPage
