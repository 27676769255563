import React from 'react'
import LinkedinIcon from 'mdi-react/LinkedinIcon'
import { Card, CardBody, CardTitle, Col } from 'reactstrap'

interface MemberCard {
  image?: string
  name: string
  title?: string
  genie?: string
  urlLinkedIn?: string
  noSpace?: boolean
  years?: string
}

const MemberCard = ({ image, name, title, genie, urlLinkedIn, years, noSpace }: MemberCard): JSX.Element => {
  return (
    <Col md="4">
      <Card className={`card-profile card-plain ${noSpace ? 'no-space' : ''}`}>
        {image && (
          <div className="card-avatar">
            <img
              className="lazyload"
              alt={name}
              data-src={image}
              title={name === 'Liliya Boyadjieva' ? 'Pitbullette' : ''}
            />
          </div>
        )}
        <CardBody className={noSpace ? 'no-space' : ''}>
          <div className="author">
            <CardTitle tag="h4">{name}</CardTitle>
            {title && <h6 className="card-category primary mb-0">{title}</h6>}
            {years && <h6 className="card-category primary">{years}</h6>}
            {genie && <p className="mb-0">{genie}</p>}
            {urlLinkedIn && (
              <a
                className="btn btn-link pt-0"
                href={urlLinkedIn}
                title="LinkedIn"
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedinIcon />
              </a>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default MemberCard
